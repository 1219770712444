<template>
  <img src="../assets/logo.jpg" alt="" class="logo">
  <div class="crypto-trade" v-if="user">
    <h2>Trade GiftCards</h2>
    <form @submit.prevent="Add">
      <label>Giftcard Category</label>
      <input type="text" name="giftcard" id="giftcard" v-model="category" />
      <label>Giftcard subCategory</label>
      <input type="text" name="category" id="giftcard" v-model="sub" />
      <label>Giftcard Country</label>
      <input type="text" name="country" id="giftcard" v-model="country" />
      <label for="amount">Enter Amount </label>
      <input type="number" name="amount" id="amount" v-model="Amount" />
      <label for="proof">Upload Front Image of GiftCard</label>
      <input type="file" name="proof" id="image" @change="UploadFront" />
      <label for="proof">Upload Back Image of GiftCard</label>
      <input type="file" name="proof" id="image" @change="UploadBack" />
      <label for="Bank_Name">Enter your Bank name</label>
      <input type="text" name="Bank_Name" id="Bank" v-model="Bank" />
      <label for="Bank_Number">Enter your Bank Account Number</label>
      <input type="text" name="Bank_Number" id="Number" v-model="AcctNo" />
      <label for="Name">Enter your Bank Account Name</label>
      <input type="text" name="Name" id="Name" v-model="AcctName" />
      <div class="card">
        <h3>Summary</h3>
        <p>GiftCard Category: {{ category }}</p>
        <p>GiftCard Subcategory: {{ sub }}</p>
        <p>GiftCard Country: {{ country }}</p>
        <p>Amount: {{ Amount }}</p>
        <p>Bank Name : {{ Bank }}</p>
        <p>Account Name :{{ AcctName }}</p>
        <p>Account Number :{{ AcctNo }}</p>
      </div>
      <button>{{ loading ? 'Loading........': 'Confirm' }}</button>
    </form>
    <div class="nothing"></div>
    <NavBar />
  </div>
</template>

<style>
form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* align-items: baseline; */
}
.crypto-trade {
  width: 80%;
  margin: auto;
  max-width: 400px;
}
.crypto-trade input,
select {
  border: 0.5px solid white;
  border-radius: 20px;
  width: 100%;
  margin: 10px auto;
  outline: white;
  background-color: white !important;
  padding: 5px 10px;
  box-sizing: border-box;
}
.crypto-trade button {
  background-color: #dc3545 !important;
  color: white !important;
}
#image {
  height: 100px;
  border-radius: 10px;
}
.nothing {
  height: 200px;
}
</style>
<script>
import NavBar from "../componets/NavBar.vue";
export default {
  components: {
    NavBar,
  },
    data() {
      return {
         category:'',
         sub:'',
         country:'',
         Amount:0,
         Bank:'',
         AcctNo:'',
         AcctName:'',
         front:'',
         back:'',
         loading: false,
      }
   },
   computed: {
      backurl() {
         return this.$store.state.back;
      },
      fronturl() {
         return this.$store.state.front;
      },
      user() {
      return this.$store.state.user;
    },
    },
   methods: {
       UploadFront(e) {
      const file = e.target.files[0];
      setTimeout(() => {
        console.log(this.front);
      }, 1000);
      if (file) {
        this.front = file;
      }
    },
     UploadBack(e) {
      const file = e.target.files[0];
      setTimeout(() => {
        console.log(this.back);
      }, 1000);
      if (file) {
        this.back = file;
      }
    },
    async Add() {
      this.loading = true
  await Promise.all([
    this.$store.dispatch("upLoadFront", { Image: this.front }),
    this.$store.dispatch("upLoadRear", { Image: this.back })
  ]);
 await new Promise((resolve) => setTimeout(resolve, 4000));
  // Proceed immediately to dispatch AddGiftCard without any delay
  await this.$store.dispatch("AddGiftCard", {
    Type: 'GiftCard',
    Category: this.category,
    SubCategory: this.sub,
    Country: this.country,
    Amount: this.Amount,
    Bank: this.Bank,
    AccountName: this.AcctName,
    AccountNumber: this.AcctNo,
    BackImage: this.backurl,
    FrontImage: this.fronturl,
    UserID: this.user.uid,
    Date: Date.now(),
    status: 'Pending'
  });
   await new Promise((resolve) => setTimeout(resolve, 1000));

   await this.$router.push('/user/logs')
},


   },
   mounted() {
    if(this.user == null) {
      this.$router.push('/')
    }
   },
};
</script>
