<template>
  <LoaDer v-if="loading && user == null" />
  <template v-else>
    <div class="transactions">
      <h3>All Transactions</h3>
      <div v-for="(Trans, i) in Transactions.sort((a,b)=> b.Date - a.Date)" :key="i">
        <div class="broad" @click="$router.push(`/user/transaction-details/${Trans.id}`)" v-if="Trans.UserID == user.uid">
          <div>
            <p>Deposit with {{ Trans.Type }}</p>
            <h4>{{ `${Trans.Amount} ${Trans.Name ? Trans.Name : Trans.Category}` }} </h4>
            <p class="t-id">{{ Trans.id }}</p>
            <p class="date"> {{ formatDate(Trans.Date) }} </p>
          </div>
        </div>
      </div>
      <div class="nothing"></div>
      <NavBar />
    </div>
  </template>
</template>
<script>
import LoaDer from "../componets/LoaDer.vue";
import NavBar from "../componets/NavBar.vue";
export default {
  components: {
    LoaDer,
    NavBar,
  },
  data() {
    return {
      loading: true,
      Transactions: this.$store.state.Transactions,
    };
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    // Wait until user is available to execute the logic
    this.$watch(
      () => this.user, // Watch the 'user' computed property
      (newUser) => {
        if (newUser) {
          // Execute this block only when the 'user' becomes available
          setTimeout(() => {
            this.$store.dispatch("getTransactions");
          }, 2000);

          if (!this.Transactions) {
            this.loading = true;
          }

          setTimeout(() => {
            this.Transactions.sort((a, b) => a.Date - b.Date);
          }, 3000);
          if (this.Transactions) {
            setTimeout(() => {
              this.loading = false;
            }, 2000);
          }
        }
      },
      { immediate: true } // Run watcher immediately
    );
  },

  methods: {
    formatDate(milliseconds) {
      const date = new Date(milliseconds);

      // Get the date part: Oct 01 2024
      const dateOptions = { month: 'short', day: '2-digit', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', dateOptions);

      // Get the time part: 07:23pm
      const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
      const formattedTime = date.toLocaleTimeString('en-US', timeOptions).toLowerCase();

      // Combine the date and time
      return `${formattedDate} ${formattedTime}`;
    }
  }
};
</script>
