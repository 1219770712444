<template>
   <img src="../assets/logo.jpg" alt="" class="logo">
    <div class="crypto-trade" v-if="user">
        <h2>Trade Crypto</h2>
        <form @submit.prevent="Add">
            <label for="option">Choose Cryptocurrency </label>
              <select  v-model="selectOption">
              <option disabled value="no">Select an Option</option>
               <option value="Bitcoin">Bitcoin</option>
               <option value="Ethereum">Ethereum</option>
               <option value="USDT(TRC20)">USDT(TRC20)</option>
               <option value="USDT(ERC20)">USDT(ERC20)</option>
              </select>

            <label for="amount">Enter Amount</label>
            <input type="number" name="amount" id="amount" v-model="Amount">
            <label for="proof">Upload proof of payment</label>
            <input type="file" name="proof" id="proof" @change="Upload">
            <label for="Bank_Name">Enter your Bank name</label>
            <input type="text" name="Bank_Name" id="Bank" v-model="Bank">
            <label for="Bank_Number">Enter your Bank Account Number</label>
            <input type="text" name="Bank_Number" id="Number" v-model="AcctNo">
            <label for="Name">Enter your Bank Account Name</label>
            <input type="text" name="Name" id="Name" v-model='AcctName'>

         <div class='card'> 
         <h3>Summary</h3>
            <p>Crypto Name: {{ selectOption }} </p>
            <p>Amount: {{ Amount }} </p>
            <p>Bank Name : {{ Bank }} </p>
            <p>Account Name :{{ AcctName }} </p>
             <p>Account Number :{{ AcctNo }} </p>
         </div>
            <button>{{ loading ? 'Loading........': 'Confirm' }}</button>
        </form>
        <div class="nothing"></div>
    <NavBar />
    </div>
</template>

<style>
 form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* align-items: baseline; */
 }
 .crypto-trade {
    width: 80%;
    margin: auto;
    max-width: 400px;
 }
 .crypto-trade input {
    border: 0.5px solid gray;
    width: 100%;
    margin: 20px auto;
    outline: white;
 }
 .crypto-trade button, .butt button {
    background:   linear-gradient(90deg, #dc143c, #1f1f1f,#ff6f61) !important;
    color: white !important;
 }
 #proof {
    height: 200px;
    border-radius: 10px;
 }
 .card {
      margin: 20px auto;
      width: 100%;
      padding: 10px 20px;
      background-color:#333333;
      color: white;
      border-radius:20px;
 }
 .card p {
   margin:10px;
 }
</style>

<script>
  import NavBar from "../componets/NavBar.vue"
  export default {
   components: {
      NavBar
   },
   data() {
      return {
         selectOption:'',
         Amount:0,
         Bank:'',
         AcctNo:'',
         AcctName:'',
         proof:'',
         loading: false,
      }
   },
   computed: {
      proofUrl() {
         return this.$store.state.proof;
      },
      user() {
      return this.$store.state.user;
    },
    },
   methods: {
       Upload(e) {
      const file = e.target.files[0];
      setTimeout(() => {
        console.log(this.front);
      }, 1000);
      if (file) {
        this.proof = file;
      }
    },
    async Add() {
      this.loading = true
      await this.$store.dispatch("upLoadProof", {
        Image: this.proof,
      });
      await new Promise((resolve) => setTimeout(resolve, 2000));
        await this.$store.dispatch("AddCrypto", {
         Type:'Crypto',
         Name: this.selectOption,
          Amount: this.Amount,
          Bank: this.Bank,
          AccountName: this.AcctName,
          AccountNumber: this.AcctNo,
          Image: this.proofUrl,
          UserID:this.user.uid,
          Date: Date.now(),
          status:'Pending'

        });
       await new Promise((resolve) => setTimeout(resolve, 1000));
       
   await this.$router.push('/user/logs')

    },
   },
   mounted() {
    if(this.user == null) {
      this.$router.push('/')
    }
   },
  }
</script>