<template>
  <LoaDer v-if="loading && user==null" />
  <template v-else>
    <div class="transactions">
      <h3>GiftCard Transactions</h3>
      <div v-for="(Trans, i) in Transactions" :key="i">
        <div class="broad" @click="$router.push(`/user/transaction-details/${Trans.id}`)" v-if="Trans.UserID==user.uid && Trans.Type=='GiftCard'">
          <div>
            <p>Deposit with {{ Trans.Type }}</p>
            <h4>{{ `${Trans.Amount} ${Trans.Name ? Trans.Name : Trans.Category}` }} </h4>
            <p class="t-id">{{ Trans.id }}</p>
            <p class="date"> {{ formatDate(Trans.Date ) }} </p>
          </div>
        </div>
      </div>
      <NavBar />
    </div>
  </template>
</template>
<script>
import LoaDer from "../componets/LoaDer.vue";
import NavBar from "../componets/NavBar.vue";
export default {
  components: {
    LoaDer,
    NavBar,
  },
  data() {
    return {
      loading: true,
      Transactions: this.$store.state.Transactions,
    };
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    // Wait until user is available to execute the logic
    this.$watch(
      () => this.user, // Watch the 'user' computed property
      (newUser) => {
        if (newUser) {
          // Execute this block only when the 'user' becomes available
          setTimeout(() => {
            this.$store.dispatch("getTransactions");
          }, 2000);

          if (!this.Transactions) {
            this.loading = true;
          }

          if (this.Transactions) {
            setTimeout(() => {
              this.loading = false;
            }, 2000);
          }
        }
      },
      { immediate: true } // Run watcher immediately
    );
  },

    methods: {
    formatDate(milliseconds) {
      const date = new Date(milliseconds);

      // Get the date part: Oct 01 2024
      const dateOptions = { month: 'short', day: '2-digit', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', dateOptions);

      // Get the time part: 07:23pm
      const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
      const formattedTime = date.toLocaleTimeString('en-US', timeOptions).toLowerCase();

      // Combine the date and time
      return `${formattedDate} ${formattedTime}`;
    }
    }
};
</script>


<style>
  .broad div {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    background-color: #3b3b3b;
    width: 80%;
    margin:20px auto;
    padding: 10px;
    border-radius: 10px;
  }
  .broad {
    width: 100%;
  }
  .transactions {
    background-color: #2c2c2c;
    height: fit-content;
    padding:30px 10px;
    color: white;
    border-radius: 5px;
  }
  .broad p {
    color: rgb(209, 208, 208);
  }
  .t-id {
    color: #ffc107 !important;
  }
  @media only screen and (max-width: 768px) {
    .transactions p, .transactions h4 {
      font-size: 10px !important;
    }
    .broad div {
      width: 90%;
    }
  }
</style>