<template>
    <div class="profile">
        <img src="../assets/logo.jpg" alt="" class="logo">
        <div class="details">
            <div class="img">
                <img src="../assets/smile.png" alt="">
            </div>
            <h2>{{ user.displayName }}</h2>
            <p>{{ user.email }}</p>
        </div>
        <div class="links">
            <div  @click="$router.push('/user/logs')">
                <img src="../assets/transactions.png" alt="">
                <h5>Transaction Logs</h5>
            </div>
            <div  @click="$router.push('/user/crypto-logs')">
                <img src="../assets/crypto.png" alt="">
                <h5>Crypto Logs</h5>
            </div>
            <div  @click="$router.push('/user/giftcard-logs')">
                <img src="../assets/transactions.png" alt="">
                <h5>GiftCard Logs</h5>
            </div>
            <div  @click="$router.push('/admin/pending')" v-if="user.uid==Admin">
                <img src="../assets/transactions.png" alt="">
                <h5>Pending Orders</h5>
            </div>
            <div  @click="$router.push('/admin/confirmed')" v-if="user.uid==Admin">
                <img src="../assets/transactions.png" alt="">
                <h5> Confirmed Orders</h5>
            </div>
            <div @click="logout">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    data-lucide="user" icon-name="user" class="lucide lucide-user">
                    <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <h5>Logout</h5>
            </div>
        </div>
    </div>
    <div class="nothing"></div>
    <NavBar />
</template>

<style>
.details {
    text-align: center;
    margin: 5% auto;
}

.details img {
    width: 150px;
}

.links div {
    display: flex;
    align-items: center;
    background-color: white;
    width: 80%;
    height: 40px;
    margin: 20px auto;
    border-radius: 10px;
    padding: 5px 10px;
}

.links img {
    width: 20px;
    margin-right: 5px;
}
.links svg {
    color: blueviolet;
    margin: 5px 0;
}
</style>
<script>
import NavBar from "../componets/NavBar.vue"
export default {
    components: {
        NavBar
    },
    computed: {
    user() {
      return this.$store.state.user;
    },
    Admin() {
            return this.$store.state.adminId;
        }
  },
  methods: {
    logout() {
      setTimeout(() => {
        this.$store.dispatch("logout");
      }, 1000);
      this.$router.push('/')
    },
  },
  mounted() {
    if(this.user == null) {
      this.$router.push('/')
    }
   },
}
</script>