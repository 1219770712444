<template>
    <section>
        <div class="image">
            <img src="../assets/Hero.png" alt="">
        </div>
        <h2>Buy & sell your giftcards</h2>
        <p>Trade your giftcards for cash or rewards at our store! Competitive rates, instant exchange and hassle-free
            process guaranteed</p>
        <RouterLink to="/login"><button>Get started</button></RouterLink>
    </section>
</template>

<style>
 section {
    width: 80%;
    margin:10% auto;
    text-align: center;
 }
 section p {
    margin-bottom:50px ;
 }
 button {
    color: white !important;
    border: 1px solid  white !important; 
    padding: 10px;
    background-color:  #b22222 !important;
    border-radius: 10px
 }
</style>