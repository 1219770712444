<template>
    <div v-if="user">
    <img src="../assets/logo.jpg" alt="" class="logo">
        <div class="welcome">
            <span>Hello {{ user.displayName }}</span>
            <img src="" alt="">
        </div>
        <div class="crypto">
            <div class="one">
                <h2>(USDT)</h2>
                <p>$0.15</p>
                <img src="../assets/chart.webp" alt="">
            </div>
            <div class="two">
                <h2>Tron</h2>
                <p>$0.15</p>
                <img src="../assets/chart.webp" alt="">
            </div>
            <div class="three">
                <h2>BTC</h2>
                <p>$0.15</p>
                <img src="../assets/chart.webp" alt="">
            </div>
            <div class="four">
                <h3>ETH</h3>
                <p>$0.15</p>
                <img src="../assets/chart.webp" alt="">
            </div>
        </div>
        <QuickDeal />
        <div class="nothing"></div>
        <NavBar />
    </div>
</template>

<style>
 .welcome {
    background-color: white;
    padding: 20px;
    height: 50px;
    box-sizing: border-box;
    width: 90%;
    margin: 5% auto;
    border-radius: 20px;
    display:flex;
align-items: center;
 }
 .logo {
   width: 100px;
   margin: 20px 5%;
 }
 .crypto {
    width: 90%;
    display: grid;
    grid-auto-flow: column;
    overflow: auto;
    margin: auto;
    box-sizing: content-box;
 }
 .crypto div {
    width: 250px;
    height: 170px;
    margin: 10px;
    box-sizing: content-box;
    border-radius: 20px;
    color: white;
    padding: 10px;
 }
 .crypto img {
    width: 100%;
    height: 80px;
 }
 .one {
    background-color: #20c997;
 }
 .two {
    background-color: #dc3545;
 }
 .three {
    background-color:#fd7e14;
 }
 .four {
    background-color: #6f42c1;
 }
 .crypto ::-webkit-scrollbar {
    display: none;
 }
</style>

<script>
  import QuickDeal from "../componets/QuickDeal.vue";
  import NavBar from "../componets/NavBar.vue"
  export default {
   components: {
      QuickDeal,
      NavBar
   },
   computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    if(this.user == null) {
      this.$router.push('/')
    }
   },
  }
  
</script>